<template lang="pug">
  .cd-sidebar-counts
    v-tooltip(bottom)
      template(v-slot:activator="{ on }")
        p(v-on="on").cd-sidebar-counts-text
          template {{ counts.courses_count }}/
      span Total

    v-tooltip(bottom)
      template(v-slot:activator="{ on }")
        p(v-on="on").cd-sidebar-counts-text
          template {{ counts.least_one_candidates_count }}/
      span Full

    v-tooltip(bottom)
      template(v-slot:activator="{ on }")
        p(v-on="on").cd-sidebar-counts-text
          template {{ counts.zero_candidates_count }}
      span Empty
</template>

<script>
export default {
  name: 'CourseDaySidebarCounts',

  props: {
    counts: { type: Object, required: true },
  },
}
</script>

<style lang="scss" scoped>
.cd-sidebar-counts {
  display: flex;
  align-items: center;

  &-text {
    font-family: $font-roboto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $label-color;
    cursor: pointer;
    margin: 0;
  }
}
</style>
