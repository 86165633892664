import { render, staticRenderFns } from "./CourseDaySidebarCounts.vue?vue&type=template&id=c423b73e&scoped=true&lang=pug&"
import script from "./CourseDaySidebarCounts.vue?vue&type=script&lang=js&"
export * from "./CourseDaySidebarCounts.vue?vue&type=script&lang=js&"
import style0 from "./CourseDaySidebarCounts.vue?vue&type=style&index=0&id=c423b73e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c423b73e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VTooltip})
